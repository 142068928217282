import React from 'react'
import './dashboard.scss';
import { Link } from 'react-router-dom';
import CititzenWeatherVA1 from './images/CitizenWeatherVA-1.png';
import CititzenWeatherVA2 from './images/CitizenWeatherVA-2.png';

export default function Dashboard() {

    return (
        <div className="dashboard-wrapper">
            <div className="header-bar">
                <h1>CitizenWeatherVA</h1>
            </div>
            <div className="dashboard">
                <h2>Introduction</h2>
                <p>In her master thesis, Dominique Hässig developed a web application that allows to
                    view these citizen reports and to perform small-scale analysis over time periods. It is possible to choose between
                    different categories of weather events and their intensities. It is also possible to look at a weather event
                    and see how many reports there are from different categories. The reports are clustered with a bubble map in pie
                    charts, and the number of reports from different categories can be analyzed over time. However, the application
                    lacks radar data to compare the reported weather events for analysis and visualization.
                    This project aims to improve the existing application by implementing a feature that enables the comparison of
                    reports from citizens with radar data. With this comparison, it should be able to view at specific weather events
                    and access additional information from the reports. Additionally, the radar data will be used to calculate the
                    trajectories of storms, which can then be compared with the information from the reports. Finally, both the radar
                    data and the reports will be visualized in an appealing manner.

                </p>
                <div className="dashboard-links-wrapper grid-container">
                    <div className="dashboard-link-item cell">
                        <Link to="/selection">
                            <img className="preview-image" src={CititzenWeatherVA1} alt="app1" />
                            <div className="dashboard-link-item__content">
                                <h4>Citizen reports visualization</h4>
                                <p>This part of the CitizenWeatherVA is the core part developed by Dominique Hässig as his master thesis. It provides several tools to analyse the citizen reports from MeteoSchweiz.</p>
                            </div>
                        </Link>

                    </div>
                    <div className="dashboard-link-item cell">
                        <Link to="/radar-data">
                            <img className="preview-image" src={CititzenWeatherVA2} alt="app1" />
                            <div className="dashboard-link-item__content">
                                <h4>Compare citizen reports to radar data</h4>
                                <p>This part of the CitizenWeatherVA helps to compare the citizen reports from MeteoSchweiz with the radar data. Currently we only have data for one specific event, although the visual comparison can be done for event 1 only.</p>
                            </div>
                        </Link>
                    </div>

                </div>
            </div>
        </div>
    )
}
